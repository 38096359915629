import { graphql } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import { MainText } from '../components/common/texts';
import ClientsMap from '../components/PageComps/ClientsPageComps/ClientPageMapComps/ClientsMap';
import ClientsBanner from '../components/PageComps/ClientsPageComps/ClientsBanner';
import ClientsReport from '../components/PageComps/ClientsPageComps/ClientsReport';
import ClientTestimonialVideos from '../components/PageComps/ClientsPageComps/clientVideos/ClientTestimonialVideos';
import { SimplePageHead, TrustedBrandIcons } from '../components/PageComps/CommonComps';
import BlogsPreview from '../components/PageComps/CommonComps/blogsPreview/BlogsPreview';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import useOnScreen from '../hooks/useOnScreen';
import { DataContext } from '../providers/DataProvider';

const video1 = {
  videoText:
    '“The FanGo system saves over 50 business days every year, and we now use them for all of our major events across the Celtic Collection. In 2022, we sold over 40,000 tickets through FanGo”',
  altText: 'Michael Wallis-Beechey',
  name: 'Michael Wallis-Beechey',
  title: 'Head of Events at The Celtic Manor Resort',
  videoId: 'IlWI7sO2HfU',
};

const video2 = {
  videoText:
    '“FanGo developed the bespoke system for us which completely revolutionised and centralised the management and sales of our events. My team use FanGo daily and they are dream to work with!”',
  altText: 'Danielle Bounds',
  name: 'Danielle Bounds',
  title: 'Sales Director at The ICC Wales',
  videoId: 'EhpXjUmUqzM',
};

const ClientsPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const sectionRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(sectionRef);

  const {
    sanityClientsPage,
    sanityCommonContent,
    allSanityPost,
    videoImage1,
    videoImage2,
  } = data;

  const { pageHead } = sanityClientsPage;
  const { title, subTitle } = pageHead;

  const { clientList } = sanityCommonContent;

  video1.videoImage = videoImage1;

  video2.videoImage = videoImage2;

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="0% 50%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={undefined}
            titlePaddingBottom={40}
            largeSubTitleSize
          />
        }
      />
      <ClientTestimonialVideos video1={video1} video2={video2} />
      <ClientsBanner sectionRef={sectionRef} renderComponent={isOnScreen} />
      <Box sx={{ backgroundColor: 'white', paddingBottom: 10 }}>
        <MainText
          textVariant="h2"
          fontColor={styles.darkBlue}
          value="Client Map"
          paddingY={styles.allFive}
        />
        <ClientsMap clientsList={clientList} />
      </Box>
      <ClientsReport data={sanityClientsPage.clients} />
      <Separator height={100} />
      <BlogsPreview
        type="caseStudy"
        data={{
          ...sanityClientsPage.caseStudy,
          blogs: allSanityPost.nodes,
        }}
      />
      <Separator height={100} />
      <TrustedBrandIcons
        data={sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default ClientsPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityClientsPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    videoImage1: file(name: { in: "MWB" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
    }

    videoImage2: file(name: { in: "DB" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
    }

    sanityClientsPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      clients {
        clientName
        clientLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 100)
          }
          altText
        }
        clientSay {
          _rawClientsItemText
          clientsItemAuthor
          clientsItemRole
          clientsItemCompany
        }
      }
      caseStudy {
        title
        buttonName
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
      clientList {
        clientCoordinates {
          latitude
          longitude
        }
        clientEventsPageURL
        clientName
        clientWebsiteURL
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 400, formats: [WEBP])
          }
          altText
        }
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;
