import { Grid, Typography } from '@mui/material';
import React from 'react';
import GridCellText from '../texts/GridCellText';

const GridCell = ({ children, xsValue, mdValue, mbValue }) => {
  return (
    <Grid item xs={12} md={4} sx={{ marginBottom: { xs: 3 } }}>
      {children}
    </Grid>
  );
};

export default GridCell;
