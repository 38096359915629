import { Grid } from '@mui/material';
import React from 'react';
import styles from '../../../common/styling/styles';
import { SubText } from '../../../common/texts';

const ClientVideoText = ({ video }) => {
  const { videoText, name, title } = video;
  return (
    <Grid item xs={12} md={6}>
      <SubText
        textAlignment={styles.alignStyleCCJJ}
        variant="h4"
        marginY={styles.allTwo}
        fontColor={styles.darkBlue}
        fontWeight="bold"
        value={videoText}
      />
      <SubText
        variant="body1"
        textAlignment={styles.alignStyleCCLL}
        marginY={styles.allOne}
        fontColor={styles.black}
        value={name}
      />
      <SubText
        variant="body1"
        textAlignment={styles.alignStyleCCLL}
        marginY={styles.allZero}
        fontColor={styles.black}
        value={title}
      />
    </Grid>
  );
};

export default ClientVideoText;
