import React from 'react';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { SubText } from '../../common/texts';
import { Box } from '@mui/material';

const paddingX = {
  xs: 2,
  sm: 2,
  md: 5,
  lg: 5,
};

const Report = ({ data }) => {
  return (
    <Box sx={{ px: styles.allTwo, py: styles.allFive }}>
      {/* <FanGoCard buttonEffect={false} width="100%" paddingX={5}> */}
      <Box
        sx={{
          textAlign: styles.alignStyleLLLL,
          color: styles.grey,
          mb: 4,
          typography: 'h6',
          fontWeight: 500,
        }}
      >
        <FanGoTextProcessor text={data._rawClientsItemText} />
      </Box>
      <SubText
        variant="h4"
        fontWeight={600}
        fontColor={styles.darkBlue}
        marginY={styles.allZero}
        value={data.clientsItemAuthor}
        textAlignment={styles.alignStyleLLLL}
      />
      <SubText
        variant="subtitle1"
        marginY={styles.allZero}
        fontColor={styles.deepGreen}
        marginBottom={styles.allThree}
        value={`${data.clientsItemRole} @${data.clientsItemCompany}`}
        textAlignment={styles.alignStyleLLLL}
      />
      {/* </FanGoCard> */}
    </Box>
  );
};

export default Report;
