import { Box, Typography } from '@mui/material';
import React from 'react';
import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import { InlineImage } from '../CommonComps';
import Report from './Report';
import { FanGoGridCarousel } from '../../common/carousel';
import { MainText } from '../../common/texts';

const ClientsReport = ({ data }) => {
  return (
    <Box sx={{ py: styles.allFive, backgroundColor: styles.white }}>
      <Typography
        variant="h2"
        sx={{
          fontColor: styles.darkBlue,
          textAlign: 'center',
          marginBottom: 5,
        }}
      >
        What Our Clients Say
      </Typography>
      {data.map((client) => {
        const carouselArray = client.clientSay.map((comment, index) => (
          <Report data={comment} key={index} />
        ));

        const severalSlides = carouselArray.length > 1;

        return (
          <Body key={client.clientLogo.altText} paddingY={styles.allOne}>
            <Box
              sx={{
                px: { xs: 2, md: 5 },
                mb: 5,
                backgroundColor: 'white',
                borderRadius: '16px',
                boxShadow: 3,
                pt: { xs: 7, md: 0 },
              }}
            >
              <Box
                sx={{
                  display: { xs: 'block', md: 'grid' },
                  gridTemplateColumns: '2fr 5fr',
                  gap: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <InlineImage data={client.clientLogo} />
                </Box>

                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                  <FanGoGridCarousel
                    array={carouselArray}
                    rows={1}
                    cols={1}
                    mobileBreakpoint={10}
                    showDots={false}
                    hideArrow={!severalSlides ? true : false}
                    bottom="0"
                    left="30%"
                    right="30%"
                  />
                </Box>

                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <FanGoGridCarousel
                    array={carouselArray}
                    rows={1}
                    cols={1}
                    mobileBreakpoint={10}
                    showDots={false}
                    hideArrow={!severalSlides ? true : false}
                    left="-28%"
                    right="115%"
                    bottom="20%"
                  />
                </Box>
              </Box>
            </Box>
          </Body>
        );
      })}
    </Box>
  );
};

export default ClientsReport;
