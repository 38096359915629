import React, { useEffect, useRef, useState } from 'react';

const IncrementingDisplayValue = ({ maxValue, type }) => {
  const [value, setValue] = useState(0);
  const count = useRef(0);

  const loadTime = 3000;
  const intervalValue = 100;
  const x = loadTime / intervalValue;
  const gradConstant = -Math.log(0.01) / x;

  useEffect(() => {
    const interval = setInterval(() => {
      count.current = count.current + 1;

      const displayValue = Math.round(
        maxValue * (1 - Math.exp(-gradConstant * count.current))
      );

      setValue(displayValue);
      if (count.current === x) {
        setValue(maxValue);
        clearInterval(interval);
      }
    }, intervalValue);
  }, []);

  if (type === '£') {
    const commaFormattedValue = value.toLocaleString('en-US');
    return (
      <>{`${type}${commaFormattedValue} ${value === maxValue ? '+' : ''}`}</>
    );
  }

  return <>{`${value}${type} ${value === maxValue ? '+' : ''}`}</>;
};

export default IncrementingDisplayValue;
