import React from 'react';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Grid } from '@mui/material';

const ClientVideoImage = ({ videoImage, altText, reverse, onClick }) => {
  const image = getImage(videoImage);

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: reverse ? 'start' : 'end',
        },
      ]}
      onClick={onClick}
    >
      <Box
        sx={[
          { transition: 'opacity 0.3s', position: 'relative' },
          (theme) => ({
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.7,
            },
          }),
        ]}
      >
        <PlayCircleOutlineIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            color: 'white',
            zIndex: 100,
            fontSize: 128,
            marginLeft: -8,
            marginTop: -8,
            opacity: '75%',
          }}
        />

        <GatsbyImage
          image={image}
          alt={altText}
          style={{ maxWidth: 400, margin: 'auto', borderRadius: '20px' }}
        />
      </Box>
    </Grid>
  );
};

export default ClientVideoImage;
