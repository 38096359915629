import { Grid } from '@mui/material';
import React from 'react';
import ClientVideoImage from './ClientVideoImage';
import ClientVideoText from './ClientVideoText';

const ClientVideo = ({ video, reverse, onClick }) => {
  const { videoImage, altText } = video;

  return (
    <Grid
      container
      justifyItems="center"
      alignItems="center"
      sx={[
        {
          margin: 'auto',
          marginTop: 5,
          textAlign: { xs: 'center', md: 'left' },
          flexDirection: {
            md: reverse ? 'row-reverse' : 'row',
            xs: 'column-reverse',
          },
        },
      ]}
    >
      <ClientVideoText video={video} />

      <ClientVideoImage
        onClick={onClick}
        videoImage={videoImage}
        altText={altText}
        reverse={reverse}
      />
    </Grid>
  );
};

export default ClientVideo;
