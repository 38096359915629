import { Box, Grid } from '@mui/material';
import React from 'react';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';
import GridCell from '../../common/grid/GridCell';
import GridCellText from '../../common/texts/GridCellText';
import IncrementingDisplayValue from '../../common/texts/IncrementingDisplayValue';

const ClientsBanner = ({ sectionRef, renderComponent }) => {
  const bannerTextValues = [
    { type: 'K', number: 100, text: 'Users of the System!' },
    { type: 'K', number: 500, text: 'Tickets Sold!' },
    {
      type: '£',
      number: 1000000,
      text: 'Drinks Pre-Orders Facilitated!',
    },
  ];

  return (
    <div ref={sectionRef}>
      <Box sx={{ width: '100%', backgroundColor: 'white', py: 3 }}>
        <Box
          sx={{
            width: '100%',
            backgroundColor: styles.darkBlue,

            background:
              'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(14,47,104,1) 35%, rgba(9,71,125,1) 100%)',
            my: 3,
            py: 8,
            color: 'white',
          }}
        >
          <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
            <MainText textVariant="h3" value="Through FanGo..." />
            <Grid container marginTop={4}>
              {bannerTextValues.map((textBlock) => {
                const { text, number, type } = textBlock;
                return (
                  <GridCell
                    key={textBlock.text}
                    xsValue={12}
                    mdValue={4}
                    mbValue={3}
                  >
                    <GridCellText
                      variant={'body2'}
                      fontSize={28}
                      textAlign="center"
                      fontWeight={'bold'}
                    >
                      {renderComponent ? (
                        <IncrementingDisplayValue
                          maxValue={number}
                          type={type}
                        />
                      ) : (
                        <>{number}</>
                      )}
                    </GridCellText>
                    <GridCellText
                      variant={'body2'}
                      fontSize={24}
                      textAlign="center"
                      fontWeight={null}
                    >
                      {text}
                    </GridCellText>
                  </GridCell>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ClientsBanner;
