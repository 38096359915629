import { Typography } from '@mui/material';
import React from 'react';

const GridCellText = ({
  variant,
  fontSize,
  textAlign,
  fontWeight,
  children,
}) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontSize,
        textAlign,
        fontWeight,
      }}
    >
      {children}
    </Typography>
  );
};

export default GridCellText;
