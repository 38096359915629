import { Box } from '@mui/material';

import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import styles from '../../../common/styling/styles';
import { MainText, SubText } from '../../../common/texts';
import FanGoDialog from '../../CommonComps/FanGoDialog';

import ClientVideo from './ClientVideo';

const ClientTestimonialVideos = ({ video1, video2 }) => {
  const [videoId, setVideoId] = useState(null);

  return (
    <Box sx={{ py: styles.allFive, backgroundColor: styles.white }}>
      {videoId && (
        <FanGoDialog
          onClose={() => {
            setVideoId(null);
          }}
          open
          fullWidth={true}
          maxWidth="md"
          backgroundColor="transparent"
        >
          <Box>
            <Box
              sx={{
                position: 'relative',
                pt: '56.25%',
              }}
            >
              <ReactPlayer
                id="MyId"
                width="100%"
                height="100%"
                style={{
                  position: 'absolute',
                  overflow: 'hidden',
                  top: 0,
                  left: 0,
                }}
                controls
                playing
                url={`https://www.youtube.com/watch?v=${videoId}`}
              ></ReactPlayer>
            </Box>
          </Box>
        </FanGoDialog>
      )}
      <Box sx={{ maxWidth: 1200, margin: 'auto', paddingX: { xs: 3, lg: 0 } }}>
        <MainText
          textVariant="h2"
          value="Client Testimonials"
          fontColor={styles.darkBlue}
        />

        <ClientVideo
          video={video1}
          onClick={() => setVideoId(video1.videoId)}
        />

        <ClientVideo
          video={video2}
          reverse
          onClick={() => setVideoId(video2.videoId)}
        />
      </Box>
    </Box>
  );
};

export default ClientTestimonialVideos;
